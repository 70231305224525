/* Contact.css */
.contact-container {
   
    color: white;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
  }
  
  .contact-title {
    color: #c6af6b;
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
  
  .contact-info {
    margin-bottom: 2rem;
    text-align: center;
  }
  
  .contact-info h2, .contact-info p {
    margin: 0.5rem 0;
  }
  
  .contact-form {
    background-color: #111825;
    border: 1px solid #c6af6b;
    padding: 2rem;
    width: 100%;
    max-width: 600px;
    border-radius: 8px;
  }
  
  .contact-form label {
    display: block;
    margin-bottom: 0.5rem;
    color: #c6af6b;
  }
  
  .contact-form input,
  .contact-form textarea {
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 1rem;
    border: 1px solid #c6af6b;
    border-radius: 4px;
    background-color: #111825;
    color: white;
  }
  
  .contact-form button {
    background-color: #c6af6b;
    color: black;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .contact-form button:hover {
    background-color: #a08d5c;
  }
  