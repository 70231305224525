@tailwind base;
@tailwind components;
@tailwind utilities;


.loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, #000000, #000000);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    color: #fff;
}
.logo {
  font-size: 66px; /* Adjust the size as needed */
  font-weight: bold;
  margin-bottom: 20px; /* Space between the logo and the spinner */
}

.spinner {
    border: 12px solid rgba(255, 255, 255, 0.3);
    border-top: 12px solid #fff;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.loading-screen p {
    margin-top: 20px;
    font-size: 18px;
    letter-spacing: 1.2px;
    animation: pulse 1.5s ease-in-out infinite;
}

@keyframes pulse {
    0% { opacity: 0.6; }
    50% { opacity: 1; }
    100% { opacity: 0.6; }
}

.dots {
    display: flex;
    margin-top: 20px;
}

.dot {
    width: 10px;
    height: 10px;
    margin: 0 5px;
    background-color: #fff;
    border-radius: 50%;
    animation: bounce 1.5s infinite ease-in-out;
}

.dot:nth-child(1) {
    animation-delay: -0.32s;
}

.dot:nth-child(2) {
    animation-delay: -0.16s;
}

@keyframes bounce {
    0%, 80%, 100% {
        transform: scale(0);
    }
    40% {
        transform: scale(1);
    }
}

/* Add this to your global CSS or create a new CSS file */
.slide-in-left {
    transform: translateX(-100%);
    opacity: 0;
  }
  
  .slide-in-right {
    transform: translateX(100%);
    opacity: 0;
  }
  
  .slide-in {
    transform: translateX(0);
    opacity: 1;
    transition: transform 1s ease-in-out, opacity 1s ease-in-out;
  }
  